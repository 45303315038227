// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  KyberNetwork,
  Messages2,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  Chart21,
  Graph,
  Dash,
  Home,
  Headphone,
  Personalcard,
  People,
  Flag,
  Activity,
  User,
  Tag2
} from 'iconsax-react';

import PieChartIcon from '@mui/icons-material/PieChart';
// type
import { NavItemType } from 'types/menu';
import { routePermissions } from 'contexts/PermissionGuard';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  charts: Chart21,
  chart: Graph,
  dash: Dash,
  pieChart: PieChartIcon,
  home: Home,
  channels: Headphone,
  department: Personalcard,
  contactgroup: People,
  campaigns: Flag,
  campaignsdashboard: Activity,
  user: User,
  tags: Tag2
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType[] = [
  {
    id: 'group-inicio',
    type: 'group',
    children: [
      {
        id: 'inicio',
        title: <FormattedMessage id="Início" />,
        type: 'item',
        icon: icons.home,
        url: '/home',
        permissions: routePermissions.home
      }
    ]
  },
  {
    id: 'group-atendimento',
    type: 'group',
    children: [
      {
        id: 'react-chat',
        title: <FormattedMessage id="Atendimento" />,
        type: 'collapse',
        icon: icons.chat,
        children: [
          {
            id: 'chat',
            title: <FormattedMessage id="Bate papo" />,
            type: 'item',
            url: '/apps/chat',
            permissions: routePermissions.chat
          },
          {
            id: 'relatorio_atendimento',
            title: <FormattedMessage id="Relatório Atendimento" />,
            type: 'item',
            url: '/apps/relatorio-atendimentos',
            permissions: routePermissions['relatorio-atendimentos']
          }
        ]
      }
    ]
  },
  {
    id: 'group-contatos',
    type: 'group',
    children: [
      {
        id: 'react-contatos',
        title: <FormattedMessage id="Contatos" />,
        type: 'collapse',
        icon: icons.profile,
        children: [
          {
            id: 'contatos',
            title: <FormattedMessage id="Lista de contatos" />,
            type: 'item',
            url: '/apps/contatos/lista',
            permissions: routePermissions.contatos
          },
          {
            id: 'grupocontatos',
            title: <FormattedMessage id="Grupo Contatos" />,
            type: 'item',
            url: '/apps/contatos/grupos',
            permissions: routePermissions.grupos
          }
        ]
      }
    ]
  },
  {
    id: 'group-canais',
    type: 'group',
    children: [
      {
        id: 'react-canais',
        title: <FormattedMessage id="Canais" />,
        type: 'collapse',
        icon: icons.channels,
        children: [
          {
            id: 'canais',
            title: <FormattedMessage id="Gerenciar canais" />,
            type: 'item',
            url: '/apps/canais',
            permissions: routePermissions.canais
          }
        ]
      }
    ]
  },
  {
    id: 'group-departamentos',
    type: 'group',
    children: [
      {
        id: 'group-departamentos',
        title: <FormattedMessage id="Departamentos" />,
        type: 'collapse',
        icon: icons.department,
        children: [
          {
            id: 'departamento',
            title: <FormattedMessage id="Gerenciar departamentos" />,
            type: 'item',
            url: '/apps/departamento',
            permissions: routePermissions.departamento
          }
        ]
      }
    ]
  },
  {
    id: 'group-campanhas',
    type: 'group',
    children: [
      {
        id: 'react-campanhas',
        title: <FormattedMessage id="Campanhas" />,
        type: 'collapse',
        icon: icons.campaigns,
        children: [
          {
            id: 'campanhas',
            title: <FormattedMessage id="Gerenciar campanhas" />,
            type: 'item',
            url: '/apps/campanhas',
            permissions: routePermissions.campanhas
          },
          {
            id: 'dashboardcampanhas',
            title: <FormattedMessage id="Dashboard de campanha" />,
            type: 'item',
            url: '/apps/dashboard-campanhas',
            permissions: routePermissions['dashboard-campanhas']
          }
        ]
      }
    ]
  },
  {
    id: 'group-usuarios',
    icon: icons.user,
    type: 'group',
    children: [
      {
        id: 'react-usuarios',
        title: <FormattedMessage id="Usuários" />,
        type: 'collapse',
        icon: icons.user,
        children: [
          {
            id: 'usario',
            title: <FormattedMessage id="Gerenciar usuários" />,
            type: 'item',
            url: '/apps/usuario',
            permissions: routePermissions.usuario
          }
        ]
      }
    ]
  },
  {
    id: 'group-tags',
    icon: icons.tags,
    type: 'group',
    permissions: routePermissions.tags,
    children: [
      {
        id: 'react-tags',
        title: <FormattedMessage id="Tags" />,
        type: 'collapse',
        icon: icons.tags,
        children: [
          {
            id: 'tags',
            title: <FormattedMessage id="Gerenciar tags" />,
            type: 'item',
            url: '/apps/tags',
            permissions: routePermissions.tags
          }
        ]
      }
    ]
  }
];

export default applications;
