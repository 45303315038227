// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { chat } from './chat';
import { showSuccessToast } from 'utils/showSuccessToast';
import { User, UsersStateProps } from 'types/users';

const url = process.env.REACT_APP_API_URL + '/api/user/v1/users';
const urlDepartments = process.env.REACT_APP_API_URL + '/api/department/v1/departments';
const urlChannels = process.env.REACT_APP_API_URL + '/api/configuration/v1/channels';
const urlProfile = process.env.REACT_APP_API_URL + '/api/user/v1/profiles';

const initialState: UsersStateProps = {
  users: [],
  channels: [],
  departaments: [],
  profiles: []
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // SET USERS
    setUsers(state, action) {
      state.users = action.payload;
    },
    // SET Channels
    setChannels(state, action) {
      state.channels = action.payload;
    },
    // SET Departaments
    setDepartaments(state, action) {
      state.departaments = action.payload;
    },
    // SET Profiles
    setProfiles(state, action) {
      state.profiles = action.payload;
    }
  }
});

export function getUsers() {
  return async () => {
    try {
      const response = await axios.get(url);
      dispatch(users.actions.setUsers(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function editUser(userData: User) {
  return async () => {
    try {
      await axios.put(`${url}/${userData._id}`, {
        _id: userData._id,
        name: userData.name,
        email: userData.email,
        username: userData.username,
        password: userData.password,
        channels: userData.channels,
        departments: userData.departments,
        profileId: userData.profileId
      });
      await dispatch(getUsers());
      showSuccessToast('Usuário editado com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addUser(userData: User) {
  return async () => {
    try {
      await axios.post(url, {
        email: userData.email,
        name: userData.name,
        password: userData.password,
        username: userData.username,
        departments: userData.departments,
        channels: userData.channels,
        profileId: userData.profileId
      });
      await dispatch(getUsers());
      showSuccessToast('Usuário criado com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function inactiveUser(userId: string) {
  return async () => {
    try {
      await axios.delete(`${url}/${userId}`);
      await dispatch(getUsers());
      showSuccessToast('Usuário inativado com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function activeUser(userData: User) {
  return async () => {
    try {
      showSuccessToast('Usuário ativo com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getChannels() {
  return async () => {
    try {
      const response = await axios.get(urlChannels);
      await dispatch(users.actions.setChannels(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getProfiles() {
  return async () => {
    try {
      const response = await axios.get(urlProfile);
      await dispatch(users.actions.setProfiles(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getDepartments() {
  return async () => {
    try {
      const response = await axios.get(urlDepartments);
      await dispatch(users.actions.setDepartaments(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export default users.reducer;
