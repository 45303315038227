// third-party
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch, store } from '../index';

// types
import { ChatStateProps, Message, OperatorType } from 'types/chat';
import { User } from 'types/user-profile';
import { updateNotificationSettingsAuth } from './auth';
import { showSuccessToast } from 'utils/showSuccessToast';
import { openSnackbar } from './snackbar';

export const url = process.env.REACT_APP_API_URL + '/api/contact/v1';
const initialState: ChatStateProps = {
  error: null,
  chatHistory: [],
  chatHistoryMeta: { rows: 0, pages: 0 },
  chatHistoryMinifed: [],
  chatHistoryMinifedMeta: { rows: 0, pages: 0 },
  user: {} as User,
  users: [],
  inAttendance: [],
  autoAttendance: [],
  waitingAttendance: [],
  departments: [],
  reasons: [],
  operators: [],
  customers: [],
  customersPage: {},
  historyAttendance: [],
  tags: [],
  quickMessages: [],
  channels: [],
  groups: [],
  managerCustomers: [],
  managerCustomersPage: { rows: 0, pages: 0 },
  reportAttendances: { meta: {}, data: [] },
  situations: [],
  allCustomers: { total: 0 },
  searchMessages: [],
  searchMessagesMeta: { rows: 0, pages: 0 },
  selectedMessageSearched: null
};

// ==============================|| SLICE - CHAT ||============================== //

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // SET USER
    setUserSuccess(state, action) {
      state.selectedMessageSearched = null;
      state.user = action.payload;
    },

    setUserTagsInAttendance(state, action) {
      const userId = action.payload._id;
      const userInAttendanceIndex = state.inAttendance.findIndex((attendance) => attendance._id === userId);
      state.inAttendance[userInAttendanceIndex].tags = action.payload.tags;
    },

    // CLEAR CHAT HISTORY
    clearChatHistory(state) {
      state.chatHistory = [];
    },

    // SET USER CHAT HISTORY
    setUserChatHistorySuccess(state, action: { type: string; payload: any }) {
      const incomingMessages =
        Array.isArray(action.payload) && action.payload.length ? action.payload : state.chatHistory;

      const uniqueMessagesMap = new Map();

      // Se a página for 0, limpa o histórico existente
      if (incomingMessages.length > 0 && incomingMessages[0].page === 0) {
        state.chatHistory = [];
      }

      const combinedChatHistory = [...incomingMessages, ...state.chatHistory];

      const filteredChatHistory = combinedChatHistory.filter((message: Message) => {
        const messageId = message._id;
        if (uniqueMessagesMap.has(messageId)) {
          return false;
        } else {
          uniqueMessagesMap.set(messageId, true);
          return true;
        }
      });

      state.chatHistory = filteredChatHistory.sort((a: Message, b: Message) => {
        if (a.page === b.page) {
          return b.timestamp - a.timestamp;
        }
        return a.page - b.page;
      });

      const newMessage = action.payload;
      if (newMessage.newChatMessage) {
        const isForCurrentUser =
          (newMessage.fromMe && newMessage.to === state.user.from) || newMessage.from === state.user.from;

        if (isForCurrentUser) {
          state.chatHistory.unshift(newMessage);
        }
      }
    },

    // set Situations
    setSituations(state, action) {
      state.situations = action.payload;
    },

    // SET CLEAR USER CHAT HISTORY
    setClearUserChatHistorySuccess(state) {
      state.chatHistory = [];
    },

    // SET USER CHAT HISTORY META
    setUserChatHistoryMetaSuccess(state, action: { type: string; payload: any }) {
      state.chatHistoryMeta = action.payload;
    },

    // CLEAR CHAT HISTORY MINIFED
    clearChatHistoryMinifed(state) {
      state.chatHistoryMinifed = [];
      state.chatHistoryMinifedMeta = { rows: 0, pages: 0 };
    },

    // set report attendances
    setReportAttendances(state, action: { type: string; payload: any }) {
      state.reportAttendances = action.payload;
    },

    // SET USER CHAT HISTORY MINIFED
    setUserChatHistoryMinifedSuccess(state, action: { type: string; payload: any }) {
      const chatHistoryMinifed =
        Array.isArray(action.payload) && action.payload.length ? action.payload : state.chatHistoryMinifed;
      const newMessage = action.payload;

      const isForCurrentUser =
        (newMessage.fromMe && newMessage.to === state.user.from) || newMessage.from === state.user.from;

      if (isForCurrentUser && newMessage.newChatMessage) {
        state.chatHistoryMinifed.unshift(newMessage);
      }

      const uniqueMessagesMap = new Map();

      const combinedchatHistoryMinifed = [...state.chatHistoryMinifed, ...chatHistoryMinifed];

      state.chatHistoryMinifed = combinedchatHistoryMinifed.filter((message: Message) => {
        const messageId = message._id;
        if (uniqueMessagesMap.has(messageId)) {
          return false;
        } else {
          uniqueMessagesMap.set(messageId, true);
          return true;
        }
      });
    },

    // SET USER CHAT HISTORY MINIFED META
    setUserChatHistoryMinifedMetaSuccess(state, action: { type: string; payload: any }) {
      state.chatHistoryMinifedMeta = action.payload;
    },

    // SET UNREAD MESSAGE
    setUnreadMessage(state, action: { type: string; payload: any }) {
      const newMessage = action.payload;

      const isForCurrentUser =
        (newMessage.fromMe && newMessage.to === state.user.from) || newMessage.from === state.user.from;

      const userInAttendanceIndex = state.inAttendance.findIndex((item) => item.from === newMessage.from);
      const userWaitingAttendanceIndex = state.waitingAttendance.findIndex((item) => item.from === newMessage.from);

      if (!isForCurrentUser) {
        // Se a mensagem não é para o usuário atual, incrementa mensagens não lidas

        if (userInAttendanceIndex !== -1) {
          state.inAttendance[userInAttendanceIndex].unreadMessages =
            (state.inAttendance[userInAttendanceIndex].unreadMessages || 0) + 1;
        }

        if (userWaitingAttendanceIndex !== -1) {
          state.waitingAttendance[userWaitingAttendanceIndex].unreadMessages =
            (state.waitingAttendance[userWaitingAttendanceIndex].unreadMessages || 0) + 1;
        }
      } else {
        state.inAttendance[userInAttendanceIndex].unreadMessages = 0;
      }
    },

    // SET CLEAR UNREAD MESSAGE
    setClearUnreadMessage(state, action: { type: string; payload: any }) {
      const userInAttendanceIndex = state.inAttendance.findIndex((item) => item.from === action.payload.from);
      const userWaitingAttendanceIndex = state.waitingAttendance.findIndex((item) => item.from === action.payload.from);

      if (userInAttendanceIndex !== -1) {
        state.inAttendance[userInAttendanceIndex].unreadMessages = 0;
      }

      if (userWaitingAttendanceIndex !== -1) {
        state.waitingAttendance[userWaitingAttendanceIndex].unreadMessages = 0;
      }
    },

    // SET USER LAST MESSAGE
    setUserLastMessage(state, action: { type: string; payload: any }) {
      const userInAttendanceIndex = state.inAttendance.findIndex((item) => item.phone === action.payload.phone);

      if (userInAttendanceIndex !== -1) {
        state.user.lastMessage = action.payload.lastMessage;
        state.inAttendance[userInAttendanceIndex].lastMessage = action.payload.lastMessage;
      }
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
    },

    // GET IN ATTENDANCE QUEUE
    getInAttendanceQueue(state, action) {
      state.inAttendance = action.payload;
    },

    // GET WAITING ATTENDANCE QUEUE
    getWaitingAttendanceQueue(state, action) {
      state.waitingAttendance = action.payload;
    },

    // GET AUTO ATTENDANCE QUEUE
    getAutoAttendanceQueue(state, action) {
      state.autoAttendance = action.payload;
    },

    // GET HISTORY ATTENDANCE QUEUE
    getHistoryAttendanceQueue(state, action) {
      state.historyAttendance = action.payload;
    },

    getTags(state, action) {
      state.tags = action.payload;
    },

    getQuickMessages(state, action) {
      state.quickMessages = action.payload;
    },

    // SET DEPARTAMENTS
    setDepartments(state, action) {
      state.departments = action.payload;
    },

    // GET CUSTOMERS
    setTotalCustomers(state, action) {
      state.allCustomers = action.payload;
    },

    getCustomers(state, action) {
      state.customers.push(...action.payload);
    },
    getCustomersPage(state, action) {
      state.customersPage = action.payload;
    },

    clearCustomers(state) {
      state.customers = [];
    },

    // Set manager customers
    setManagerCustomers(state, action) {
      state.managerCustomers.push(...action.payload);
    },

    getManagerCustomersPage(state, action) {
      state.managerCustomersPage = action.payload;
    },

    clearManagerCustomers(state) {
      state.managerCustomers = [];
    },

    setOperators(state, action) {
      state.operators = action.payload;
    },

    // GET CHANNELS
    getChannels(state, action) {
      state.channels = action.payload;
    },

    // GET GROUPS
    getGroups(state, action) {
      state.groups = action.payload;
    },

    // SET RESONS TYPES
    setResons(state, action) {
      state.reasons = action.payload;
    },

    updateMessage(state, action: PayloadAction<Message>) {
      const index = state.chatHistory.findIndex((msg) => msg._id === action.payload._id);
      const attendanceIndex = state.inAttendance.findIndex((item) => item.lastMessage?._id === action.payload._id);
      if (index !== -1) {
        state.chatHistory[index] = action.payload;
      }
      if (attendanceIndex !== -1) {
        state.inAttendance[attendanceIndex].lastMessage = action.payload;
      }
    },

    // Reducer para estado da mensagem(enviado(1), recebido(2), vizualizado(3))
    updateMessageAck(state, action: PayloadAction<{ id: string; ack: number }>) {
      const { id, ack } = action.payload;
      const messageIndex = state.chatHistory.findIndex((msg) => msg._id === id);
      const attendanceIndex = state.inAttendance.findIndex((item) => item.lastMessage?._id === id);
      if (messageIndex !== -1) {
        state.chatHistory[messageIndex].ack = ack;
      }
      if (attendanceIndex !== -1) {
        state.inAttendance[attendanceIndex].lastMessage!.ack = ack;
      }
    },

    // Reducer para atualizar as reações das mensagens
    updateMessageReaction(state, action: PayloadAction<{ id: string; reaction: string; senderId: string }>) {
      const { id, reaction, senderId } = action.payload;

      // Procura a mensagem pelo ID
      const messageIndex = state.chatHistory.findIndex((msg) => msg._id === id);

      if (messageIndex !== -1) {
        const message = state.chatHistory[messageIndex];

        // Inicializa o array de reações se não existir
        if (!message.reactions) {
          message.reactions = [];
        }

        // Procura a reação associada ao senderId fornecido
        const reactionIndex = message.reactions.findIndex((r) => r.senderId === senderId);

        if (reactionIndex !== -1) {
          // Se já existe uma reação, atualiza a reação e a data
          message.reactions[reactionIndex].reaction = reaction;
          message.reactions[reactionIndex].updatedAt = new Date().toISOString();
        } else {
          // Se não existe, adiciona uma nova reação
          message.reactions.push({
            reaction: reaction,
            senderId: senderId,
            timestamp: Math.floor(Date.now() / 1000),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          });
        }
      }
    },

    // Reducer atualizar a mensagem quando ela for apagada.
    updateMessageRevoke(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const messageIndex = state.chatHistory.findIndex((msg) => msg._id === id);
      const attendanceIndex = state.inAttendance.findIndex((item) => item.lastMessage?._id === id);

      if (messageIndex !== -1) {
        state.chatHistory[messageIndex].deletedAt = new Date().toISOString();
      }
      if (attendanceIndex !== -1) {
        state.inAttendance[attendanceIndex].lastMessage!.deletedAt = new Date().toISOString();
      }
    },

    // Atualizar wallet
    updateWallet(state, action: PayloadAction<{ walletOperatorId: string; user: User; join: boolean }>) {
      const { walletOperatorId, user, join } = action.payload;
      const attendanceIndex = state.inAttendance.findIndex((item) => item._id === user._id);
      const waitingIndex = state.waitingAttendance.findIndex((item) => item._id === user._id);

      // Atualize o walletOperatorId do usuário atual
      if (join) {
        state.user.walletOperatorId = walletOperatorId;
        if (attendanceIndex !== -1) {
          state.inAttendance[attendanceIndex].walletOperatorId = walletOperatorId;
        }
        if (waitingIndex !== -1) {
          state.waitingAttendance[waitingIndex].walletOperatorId = walletOperatorId;
        }
      } else {
        state.user.walletOperatorId = null;
        if (attendanceIndex !== -1) {
          state.inAttendance[attendanceIndex].walletOperatorId = null;
        }
        if (waitingIndex !== -1) {
          state.waitingAttendance[waitingIndex].walletOperatorId = null;
        }
      }
    },

    // Set search Message
    setSearchMessage(state, action) {
      const newMessages = action.payload;

      // Define uma estrutura de dados para mensagens únicas (evitar duplicados)
      const uniqueMessagesMap = new Map(state.searchMessages.map((msg) => [msg._id, msg]));

      // Adiciona novas mensagens, evitando duplicados
      newMessages.forEach((message: Message) => {
        uniqueMessagesMap.set(message._id, message);
      });

      // Atualiza o estado com as mensagens únicas
      state.searchMessages = Array.from(uniqueMessagesMap.values());
    },

    setSearchMessageMeta(state, action) {
      state.searchMessagesMeta = action.payload;
    },

    // Clear search Message
    clearSearchMessage(state) {
      state.searchMessages = [];
    },

    // Set Selected message searched
    setSelectedMessageSearched(state, action) {
      state.selectedMessageSearched = action.payload;
    },
    clearSelectedMessageSearched(state) {
      state.user = {} as User;
      state.selectedMessageSearched = null;
    }
  }
});

// Reducer
export default chat.reducer;

export function getUser(id: number) {
  return async () => {
    try {
      const response = await axios.post('/api/chat/users/id', { id });
      dispatch(chat.actions.setUserSuccess(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function insertChat(chat: any) {
  return async () => {
    try {
      await axios.post('/api/chat/insert', chat);
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getUsers() {
  return async () => {
    try {
      const response = await axios.get('/api/chat/users');
      dispatch(chat.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getInAttendance() {
  return async () => {
    try {
      const response = await axios.get(`${url}/in-attendances`);
      dispatch(chat.actions.getInAttendanceQueue(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getWaitingAttendance() {
  return async () => {
    try {
      const response = await axios.get(`${url}/waiting-attendances`);
      dispatch(chat.actions.getWaitingAttendanceQueue(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getAutoAttendance() {
  return async () => {
    try {
      const response = await axios.get(`${url}/auto-attendances`);
      dispatch(chat.actions.getAutoAttendanceQueue(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getAllCustomers() {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-active-contacts`);
      dispatch(chat.actions.setTotalCustomers(response.data));

      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getCustomers(page: number, filter?: string, status?: string) {
  return async (dispatch: any) => {
    try {
      if (page === 1) dispatch(clearCustomers());

      const params = new URLSearchParams({
        filter: filter || '',
        page: page.toString(),
        status: status === 'all' ? '' : status || ''
      });

      const response = await axios.get(`${url}/customers?${params.toString()}`);

      const { meta, data } = response.data;

      dispatch(chat.actions.getCustomersPage(meta));
      dispatch(chat.actions.getCustomers(data));

      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function updateCustomer(customer: any, page: number) {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/customers/${customer.phone}`, customer);
      dispatch(getCustomers(page));
      showSuccessToast('Usuario atualizado com sucesso.');
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteCustomer(phone: string, channelId: string) {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/customers/${phone}?channelId=${channelId}`);
      showSuccessToast('Contato deletado com sucesso.');
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addCustomers(
  channels: string[],
  externalIds: any[],
  groups: any[],
  name: string,
  phone: string,
  tags: any[]
) {
  return async () => {
    try {
      const response = await axios.post(`${url}/customers`, {
        channels,
        externalIds,
        groups,
        name,
        phone,
        tags
      });
      dispatch(chat.actions.getCustomers(response.data.data));
      showSuccessToast('Contato adicionado com sucesso.');
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getChannels() {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/configuration/v1/channels`);

      const data = response.data;

      dispatch(chat.actions.getChannels(data));
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getGroups(order?: string, order_field?: string, channelId?: string, active?: boolean) {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${url}/groups?&order=${order || 'asc'}&order_field=${order_field || 'createAt'}&channelId=${
          channelId || ''
        }&active=${active || 'true'}`
      );

      const data = response.data;

      dispatch(chat.actions.getGroups(data));
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function postGroup(channelId: string, contacts: { id: string }[], description: string, name: string) {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`${url}/groups`, {
        channelId,
        contacts,
        description,
        name
      });

      showSuccessToast('Grupo criado com sucesso.');
      dispatch(getGroups());
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function putGroup(
  groupId: string,
  channelId: string,
  contacts: { id: string }[],
  description: string,
  name: string
) {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/groups/${groupId}`, {
        channelId,
        contacts,
        description,
        name
      });

      showSuccessToast('Grupo editado com sucesso.');
      dispatch(getGroups());
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteGroup(groupId: string) {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/groups/${groupId}`);

      showSuccessToast('Grupo deletado com sucesso.');
      dispatch(getGroups());
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

// puxar os atendimentos
export function getReportAttendances(dateFrom: string, dateTo: string, page: number, filters: any) {
  return async () => {
    const params = new URLSearchParams({
      dateFrom,
      dateTo,
      page: page.toString(),
      order: 'desc',
      ...(filters.status && { status: filters.status }),
      ...(filters.operatorId && { operatorId: filters.operatorId }),
      ...(filters.channelId && { channelId: filters.channelId }),
      ...(filters.departmentIds && { departmentIds: filters.departmentIds }),
      ...(filters.reasonTypeIds && { reasonTypeIds: filters.reasonTypeIds }),
      ...(filters.customerId && { customerId: filters.customerId })
    });

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report-attendance/v1/attendances?${params.toString()}`
      );
      dispatch(chat.actions.setReportAttendances(response.data));
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

// function que executa o primeiro get para buscar o chat
export function getHistoryAttendance(phone: string, channelId: string, page: number) {
  return async () => {
    try {
      const response = await axios.get(
        `${url}/customers/${phone}/history-attendances?channelId=${channelId}&order=desc&page=${page}`
      );

      const chatHistoryWithPage = response.data.data.map((message: any) => ({
        ...message,
        page: page
      }));

      dispatch(chat.actions.setUserChatHistorySuccess(chatHistoryWithPage));

      // Atualiza os metadados
      dispatch(chat.actions.setUserChatHistoryMetaSuccess(response.data.meta));
      return response;
    } catch (error) {
      console.error(error); // Para capturar e visualizar qualquer erro
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function postStartAttendance(cellPhone: string, channelId: string, departmentId?: string) {
  return async () => {
    try {
      dispatch(setUserSuccess({}));
      // requisição para iniciar o atendimento.
      await axios.post(`${url}/start-attendances`, { cellPhone, channelId, departmentId });

      await dispatch(getInAttendance());

      // busca no estado o usuário que corresponde ao ID e telefone.
      const state = store.getState();
      const inAttendance = state.chat.inAttendance;

      // Encontra o usuário pelo telefone
      const userFound = inAttendance.find((user) => user.phone === cellPhone);
      dispatch(setUserSuccess(userFound));
      dispatch(setClearUserChatHistorySuccess());
      await dispatch(getHistoryAttendance(userFound?.phone || '', userFound?.channel?.id || '', 1));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

// function que recebe os dados do mini chat
export function getHistoryMinifed(phone: string, channelId: string, page: number, dateFrom?: string) {
  return async () => {
    try {
      let queryParams = `channelId=${channelId}&order=desc&page=${page}`;
      if (dateFrom) {
        queryParams += `&dateFrom=${encodeURIComponent(dateFrom)}`;
      }

      const response = await axios.get(`${url}/customers/${phone}/history-attendances?${queryParams}`);

      dispatch(chat.actions.setUserChatHistoryMinifedSuccess(response.data.data));
      dispatch(chat.actions.setUserChatHistoryMinifedMetaSuccess(response.data.meta));
      return response;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function putFinishAttendance(id?: string, reason?: string, description?: string) {
  return async () => {
    try {
      const response = await axios.put(`${url}/attendances/${id}/finishes`, {
        reasonTypeId: reason,
        reasonDescription: description
      });

      showSuccessToast('Atendimento finalizado com sucesso!');

      dispatch(clearChatHistory());
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
      throw error;
    }
  };
}

export function putWallet(user?: User, join?: boolean, walletOperatorId?: string) {
  return async () => {
    try {
      const response = await axios.put(`${url}/customers/${user?.phone}/wallets`, {
        channelId: user?.channel?.id,
        join: join
      });
      if (walletOperatorId && user) {
        dispatch(updateWallet({ walletOperatorId, user, join: join ? join : false }));
      }
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function changeDepartament(
  cellphone?: string,
  channelId?: string,
  departmentId?: string,
  privateMessage?: string
) {
  return async () => {
    try {
      const response = await axios.put(`${url}/waiting-attendances/${cellphone}/change-departments`, {
        channelId: channelId,
        departmentId: departmentId,
        privateMessage: privateMessage
      });
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function postTransfer(
  userId?: string,
  departmentId?: string,
  privateMessage?: string,
  operatorId?: OperatorType
) {
  return async () => {
    try {
      const response = await axios.post(`${url}/attendances/${userId}/transfers`, {
        departmentId: departmentId,
        privateMessage: privateMessage,
        userId: operatorId
      });
      dispatch(clearChatHistory());
      dispatch(setUserSuccess({}));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Transfêrencia concluida.',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getDepartments() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/department/v1/departments`);
      dispatch(chat.actions.setDepartments(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getOperatos(id: string) {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/v1/users?departmentId=${id}`);
      dispatch(chat.actions.setOperators(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getReasons() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/attendance/v1/reason-types`);
      dispatch(chat.actions.setResons(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getTags() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/configuration/v1/tags`);
      dispatch(chat.actions.getTags(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function removeTag(user: User, tagId: string) {
  return async () => {
    try {
      await axios.delete(`${url}/customers/${user.phone}/tags/${tagId}?channelId=${user.channel?.id}`);
      dispatch(setUserSuccess(user));
      dispatch(setUserTagsInAttendance(user));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addTag(user: User, tagId: string) {
  return async () => {
    try {
      await axios.post(`${url}/customers/${user.phone}/tags`, { tagId, channelId: user.channel?.id });
      dispatch(setUserSuccess(user));
      dispatch(setUserTagsInAttendance(user));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getQuickMessages() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/configuration/v1/quick-messages`);
      dispatch(chat.actions.getQuickMessages(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addQuickMessage(message: string, shortcut: string, visibility: string) {
  return async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/configuration/v1/quick-messages`, {
        message,
        shortcut,
        visibility
      });
      await dispatch(getQuickMessages());
    } catch (error) {
      dispatch(chat.actions.hasError(error));
      throw error;
    }
  };
}

export function editQuickMessage(id: string, message: string, shortcut: string, visibility: string) {
  return async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/configuration/v1/quick-messages/${id}`, {
        message,
        shortcut,
        visibility
      });
      await dispatch(getQuickMessages());
    } catch (error) {
      dispatch(chat.actions.hasError(error));
      throw error;
    }
  };
}

export function removeQuickMessage(id: string) {
  return async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/configuration/v1/quick-messages/${id}`);
      await dispatch(getQuickMessages());
    } catch (error) {
      dispatch(chat.actions.hasError(error));
      throw error;
    }
  };
}

export function getManagerCustomers(filter: string, page: number, status: string | 'Active') {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/contact/v1/manager-customers?filter=${filter}&page=${page}&status=${status}`
      );
      dispatch(chat.actions.getManagerCustomersPage(response.data.meta));
      dispatch(chat.actions.setManagerCustomers(response.data.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
      throw error;
    }
  };
}

export function changeNotificationSettings() {
  return async () => {
    try {
      const state = store.getState();
      const notificationSettings = state.auth.user?.settings?.notification;

      if (!notificationSettings) {
        throw new Error('Configurações de notificação não encontradas no estado.');
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/api/user/v1/notification-settings`, {
        inAttendanceMail: notificationSettings.inAttendanceMail,
        inAttendancePopup: notificationSettings.inAttendancePopup,
        inAttendanceSound: notificationSettings.inAttendanceSound,
        waitingAttendanceMail: notificationSettings.waitingAttendanceMail,
        waitingAttendancePopup: notificationSettings.waitingAttendancePopup,
        waitingAttendanceSound: notificationSettings.waitingAttendanceSound,
        updatedAt: new Date().toISOString()
      });

      dispatch(
        updateNotificationSettingsAuth({
          ...notificationSettings
        })
      );
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getMessagePage(cellPhone: string, messageId: string, pageSize: number, channelId: string) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${cellPhone}/messages/${messageId}/pages`,
        {
          params: {
            pageSize,
            channelId
          }
        }
      );
      return response.data.page;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getCustomer(cellPhone: string) {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${cellPhone}`);
      return response.data;
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getSituations() {
  return async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/attendance/v1/attendance-type-status`);
      dispatch(chat.actions.setSituations(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function getSearchMessage(content: string, page?: number, pageSize?: number) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/contact/v1/history-messages?content=${content}&page=${
          page || 1
        }&pageSize=${pageSize || 10}`
      );
      dispatch(chat.actions.setSearchMessage(response.data.data));
      dispatch(chat.actions.setSearchMessageMeta(response.data.meta));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function patchPin(cellPhone: string, pin: boolean, channelId: string) {
  return async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${cellPhone}/pin-conversations`, {
        pin,
        channelId
      });

      let pinDate: string | null = null;

      if (pin) {
        pinDate = new Date().toISOString();
      }

      const state = store.getState();
      const currentUser = state.chat.user;
      if (currentUser.phone === cellPhone) {
        const updatedUser = { ...currentUser, pinDate };
        dispatch(chat.actions.setUserSuccess(updatedUser));
      }

      const updatedInAttendance = state.chat.inAttendance.map((user) =>
        user.phone === cellPhone ? { ...user, pinDate } : user
      );

      dispatch(chat.actions.getInAttendanceQueue(updatedInAttendance));

      showSuccessToast(`Conversa ${pin ? 'fixada' : 'desfixada'} com sucesso.`);
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export const {
  clearManagerCustomers,
  clearChatHistory,
  setUserChatHistorySuccess,
  setClearUserChatHistorySuccess,
  setUserChatHistoryMetaSuccess,
  clearChatHistoryMinifed,
  setUserChatHistoryMinifedSuccess,
  setUserChatHistoryMinifedMetaSuccess,
  setUserSuccess,
  setUnreadMessage,
  setClearUnreadMessage,
  setUserLastMessage,
  clearCustomers,
  setUserTagsInAttendance,
  updateMessage,
  updateMessageAck,
  updateMessageReaction,
  updateMessageRevoke,
  updateWallet,
  clearSearchMessage,
  setSelectedMessageSearched,
  clearSelectedMessageSearched
} = chat.actions;
