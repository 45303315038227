import { User } from 'types/user-profile';

// ==============================|| TYPES - CHAT  ||============================== //

export type OperatorType = null | string;

export enum OperatorTypes {
  ALL = 'Todos',
  ONLINE = 'online'
}

export enum ChangeMessageTypes {
  ADD = 'add',
  REMOVE = 'remove',
  EDIT = 'edit'
}

export enum QuickMessageVisibility {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export enum AckTypes {
  SENT = 1,
  RECEIVED = 2,
  VIEWED = 3,
  NONE = 0
}

export interface ChatHistory {
  data?: Message[];
  meta?: Meta;
}

export interface File {
  type: string;
  mediaType: string;
  content: string;
  attachment: string;
  body: string;
}

export interface Meta {
  pages?: number;
  rows?: number;
}

export interface QuickMessage {
  createdAt: string;
  message: string;
  operatorId: string;
  operatorName: string;
  shortcut: string;
  updatedAt: string;
  visibility: string;
  _id: string;
}

export interface ChatStateProps {
  searchMessages: Message[];
  selectedMessageSearched: Message | null;
  chatHistory: Message[];
  chatHistoryMeta: { rows: number; pages: number };
  chatHistoryMinifed: Message[];
  chatHistoryMinifedMeta: { rows: number; pages: number };
  user: User;
  users: User[];
  error: object | string | null;
  waitingAttendance: User[];
  inAttendance: User[];
  autoAttendance: User[];
  historyAttendance: Message[];
  tags: Tag[];
  departments: Departaments[];
  customers: User[];
  customersPage: Meta;
  reasons: Reasons[];
  operators: Operators[];
  quickMessages: QuickMessage[];
  channels: Channels[];
  groups: Group[];
  reportAttendances: reportAttendance;
  managerCustomers: User[];
  managerCustomersPage: Meta;
  situations: { name: string; description: string }[];
  allCustomers: { total: number };
  searchMessagesMeta: Meta;
}

export interface reportAttendance {
  meta: Meta;
  data: reportAttendanceData[];
}

export interface Log {
  createdAt: string;
  departmentId: string;
  departmentName: string;
  operatorId: string;
  operatorName: string;
  status: string;
}

export interface reportAttendanceData {
  channel: { id: string; cellPhone: string; name: string; shortName: string };
  customer: { id: string; name: string; cellPhone: string; image: string };
  department: { id: string; name: string };
  logs: Log[];
  operator: { id: string; name: string };
  protocol: string;
  startedAt: string;
  finishedAt: string;
  status: string;
  _id: string;
  reason?: { description: string; type: string; typeid: string; typeName: string };
}
export interface Group {
  channel: ChannelOperators;
  contacts: Contact[];
  createdAt: string;
  deletedAt: string;
  description: string;
  name: string;
  updatedAt: string;
  _id: string;
  id?: string;
}

export interface Contact {
  id: string;
  image: string;
  name: string;
  phone: string;
}

export interface Channels {
  businessHours: Week;
  cellPhone: string;
  chatBotId: string;
  contactIntegration: string;
  createdAt: string;
  deliveryHours: Week;
  email: string;
  image: string;
  name: string;
  remoteId: string;
  remoteId2: string;
  shortName: string;
  status: string;
  updatedAt: string;
  zipCode: number;
  _id: string;
}

export interface Week {
  friday: Times;
  monday: Times;
  saturday: Times;
  sunday: Times;
  thursday: Times;
  tuesday: Times;
  wednesday: Times;
}

export interface Times {
  close: string;
  open: string;
}

export interface ContactInfo {
  name: string;
  number: string;
}

export type ChangeType = 'remove' | 'edit' | 'add';

export interface MessageContent {
  body: string;
  deletedAt?: string;
  attachment: string;
  attachmentName?: string;
  type: string;
  hasMedia: boolean;
  quotedMsg?: QuotedMsg;
  departmentName?: string;
  operatorName?: string;
  sendError?: boolean;
  bodyEdited?: string;
}

export interface Departaments {
  createdAt: string;
  main: boolean;
  name: string;
  totalUsers: number;
  updatedAt: string;
  _id: string;
}

export interface Operators {
  channels: ChannelOperators;
  createdAt: string;
  deactivatedAt: null;
  departments: ChannelOperators[];
  disconnectedAt: string;
  email: string;
  name: string;
  profile: ChannelOperators;
  status: string;
  updatedAt: string;
  username: string;
  _id: string;
}

export interface Reasons {
  descriptionRequired: boolean;
  name: string;
  type: string;
  _id: string;
}

export interface Tag {
  createdAt: string;
  name: string;
  operatorId: string;
  operatorName: string;
  totalContacts: number;
  type: string;
  updatedAt: string;
  _id: string;
  id: string;
}

export interface Reaction {
  reaction: string;
  senderId?: string;
  timestamp: number;
  updatedAt: string;
  createdAt: string;
}

export interface Message {
  _id: string;
  from: string;
  page: number;
  fromMe: boolean;
  fromName: string;
  to: string;
  body: string;
  cellPhone: string;
  reference: string;
  origin: string;
  type: string;
  hasMedia: boolean;
  operatorId: string;
  operatorName: string;
  departmentId: string;
  departmentName: string;
  timestamp: number;
  ack: number;
  channel: Channel;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  attachment: string;
  attachmentName?: string;
  quotedMsg?: QuotedMsg;
  edited: boolean;
  sendError: boolean;
  reactions: Reaction[];
  newChatMessage: boolean;
  isEdited: boolean;
}

export interface QuotedMsg {
  attachment: any;
  body: string;
  from: string;
  hasMedia: boolean;
  id?: string;
  origin: string;
  to: string;
  type: string;
  deletedAt?: string;
  fromMe?: boolean;
  fromName?: string;
  _id?: string;
}

export interface Channel {
  id: string;
  name?: string;
  shortName?: string;
  cellPhone?: string;
  chatBotId?: string;
  image?: string;
}

interface ChannelOperators {
  id: string;
  name: string;
}

export interface Queue {
  action: Action;
  from: string;
  type: QueueType;
}

export enum Action {
  In = 'in',
  Out = 'out'
}

export enum QueueType {
  Auto = 'auto',
  WaitingAttendance = 'waitingAttendance',
  InAttendance = 'inAttendance'
}

export enum SituationStatus {
  STARTED = 'started',
  TRANSFER = 'transfer',
  FINISHED = 'finished',
  AUTOFINISHED = 'autoFinished',
  AUTOSTARTED = 'autoStarted'
}
