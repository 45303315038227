/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, createContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { dispatch, useSelector } from 'store';
import Loader from 'components/Loader';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router';
import { JWTContextType } from 'types/auth';
import axios from 'utils/axios';
import { getItemLocalStorage } from 'hooks/useLocalStorage';
import { getLoggedUsers, setLogin, setLogout } from 'store/reducers/auth';

const accessToken = getItemLocalStorage('accessToken');

export const isTokenValid = (token: string) => {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token) as any;
  const expirationTimestamp = decodedToken.exp * 1000;

  const currentTimestamp = Date.now();
  return currentTimestamp < expirationTimestamp;
};

const setSession = (accessToken?: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);
const url = process.env.REACT_APP_API_URL + '/api/user/v1';

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const { isLoggedIn, isInitialized, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getLoggedUsers());
  }, [accessToken]);

  const login = async (username: string, password: string) => {
    const credentials = `${username}:${password}`;
    const encodedCredentials = window.btoa(credentials);
    const authHeader = `Basic ${encodedCredentials}`;
    const response = await axios.post(
      `${url}/login`,
      {},
      {
        headers: {
          Authorization: authHeader
        }
      }
    );
    const { access_token } = response.data;
    setSession(access_token);
    dispatch(
      setLogin({
        user: jwtDecode(access_token)
      })
    );
    window.location.reload();
  };

  const logout = () => {
    setSession(null);
    dispatch(setLogout());
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  useEffect(() => {
    const init = async () => {
      try {
        if (accessToken && isTokenValid(accessToken)) {
          setSession(accessToken);
          dispatch(
            setLogin({
              user: jwtDecode(accessToken)
            })
          );

          navigate(pathname, { replace: true });
        } else if (accessToken && !isTokenValid(accessToken)) {
          enqueueSnackbar('Sessão expirada.', { variant: 'error', autoHideDuration: 3000 });
          dispatch(setLogout());
          logout();
        }
      } catch (err) {
        console.error(err);
        dispatch(setLogout());
      }
    };

    init();
  }, []);

  if (isInitialized !== undefined && !isInitialized && user) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ user, isLoggedIn, isInitialized, login, logout, resetPassword, updateProfile }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
