/* eslint-disable @typescript-eslint/no-unused-vars */
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container } from '@mui/material';

// project-imports
import Drawer from './Drawer';
// import Header from './Header';
import HorizontalBar from './Drawer/HorizontalBar';

import { DRAWER_WIDTH } from 'config';
import useConfig from 'hooks/useConfig';

// types
import { MenuOrientation } from 'types/config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100vh' }}>
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box
        component="main"
        sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, p: { xs: 2, md: 3 } }}
      >
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            height: '100%',
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
