import { useState, useEffect } from 'react';
import { Divider, Switch } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import SimpleBar from 'simplebar-react';
import { dispatch, useSelector } from 'store';
import { updateNotificationSettingsAuth } from 'store/reducers/auth';

const NotificationSettings = () => {
  const authUser = useSelector((state) => state.auth.user);

  const [localSettings, setLocalSettings] = useState({
    inAttendanceSound: authUser?.settings?.notification?.inAttendanceSound || false,
    inAttendancePopup: authUser?.settings?.notification?.inAttendancePopup || false,
    waitingAttendanceSound: authUser?.settings?.notification?.waitingAttendanceSound || false,
    waitingAttendancePopup: authUser?.settings?.notification?.waitingAttendancePopup || false,
    inAttendanceMail: authUser?.settings?.notification?.inAttendanceMail || false,
    waitingAttendanceMail: authUser?.settings?.notification?.waitingAttendanceMail || false
  });

  const handleToggle = (settingKey: string, value: boolean) => {
    setLocalSettings((prev) => ({
      ...prev,
      [settingKey]: value
    }));
  };

  useEffect(() => {
    dispatch(updateNotificationSettingsAuth(localSettings));
  }, [localSettings]);

  return (
    <Box
      sx={{
        mt: 0,
        padding: 1,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',
        borderTop: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }}
    >
      <SimpleBar
        style={{
          maxHeight: '200px'
        }}
      >
        <Stack>
          <Box>
            <Typography fontWeight="bold">Em atendimento</Typography>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={localSettings.inAttendanceSound}
                onChange={(e) => {
                  handleToggle('inAttendanceSound', e.target.checked);
                }}
              />
              <Typography variant="h6">Aviso sonoro</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={localSettings.inAttendancePopup}
                onChange={(e) => {
                  handleToggle('inAttendancePopup', e.target.checked);
                }}
              />
              <Typography variant="h6">Aviso janela pop-up</Typography>
            </Stack>
          </Box>
          <Box>
            <Typography fontWeight="bold">Aguardando atendimento</Typography>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={localSettings.waitingAttendanceSound}
                onChange={(e) => {
                  handleToggle('waitingAttendanceSound', e.target.checked);
                }}
              />
              <Typography variant="h6">Aviso sonoro</Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
            >
              <Switch
                size="small"
                checked={localSettings.waitingAttendancePopup}
                onChange={(e) => {
                  handleToggle('waitingAttendancePopup', e.target.checked);
                }}
              />
              <Typography variant="h6">Aviso janela pop-up</Typography>
            </Stack>
          </Box>
        </Stack>
      </SimpleBar>
    </Box>
  );
};

export default NotificationSettings;
