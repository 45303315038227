import { getItemLocalStorage } from 'hooks/useLocalStorage';
import { sendNotification } from 'hooks/useNotifications';
import { getSocketInstance } from 'service/SocketService';
import { dispatch, store } from 'store';
import {
  getAutoAttendance,
  getInAttendance,
  getWaitingAttendance,
  setUnreadMessage,
  setUserChatHistorySuccess,
  setUserLastMessage,
  updateMessage,
  updateMessageAck,
  updateMessageReaction,
  updateMessageRevoke
} from 'store/reducers/chat';
import { openSnackbar } from 'store/reducers/snackbar';
import { setSocketConnected } from 'store/reducers/socket';
import { playNotificationSound } from 'store/reducers/soundSlice';
import { Queue, QueueType } from 'types/chat';
import { ChatWebsocketEvent } from 'utils/websocket-events';

const accessToken = getItemLocalStorage('accessToken');
const socketInstance = getSocketInstance(accessToken);

export const initWS = () => {
  socketInstance.on(ChatWebsocketEvent.ChatbotMessage, (message) => {
    dispatch(
      setUserLastMessage({
        lastMessage: {
          ...message,
          createdAt: new Date().toISOString()
        },
        phone: message.cellPhone
      })
    );
    dispatch(
      setUserChatHistorySuccess({
        ...message,
        newChatMessage: true,
        createdAt: new Date().toISOString()
      })
    );
  });

  socketInstance.on(ChatWebsocketEvent.ChatbotQueue, (queue: Queue) => {
    if (queue.type === QueueType.Auto) {
      dispatch(getAutoAttendance());
      return;
    }

    if (queue.type === QueueType.WaitingAttendance) {
      const authUser = store.getState().auth.user;

      if (authUser?.settings?.notification?.waitingAttendanceSound) {
        dispatch(playNotificationSound());
      }
      if (authUser?.settings?.notification?.waitingAttendancePopup) {
        sendNotification('Estão te aguardando para serem atendidos', {
          body: 'Volte para conferir!',
          icon: '/favicon.svg',
          url: '/app/chat'
        });
      }

      return dispatch(getWaitingAttendance());
    }

    if (queue.type === QueueType.InAttendance) {
      dispatch(getInAttendance());
      return;
    }
  });

  socketInstance.on(ChatWebsocketEvent.ChatbotMessageUnread, (message) => {
    const authUser = store.getState().auth.user;
    const actualUser = store.getState().chat.user;

    if (actualUser && actualUser.phone === message.from) {
      return;
    }
    if (message.attendanceType === QueueType.InAttendance && authUser?.settings.notification.inAttendanceSound) {
      dispatch(playNotificationSound());
    }
    if (authUser?.settings.notification.inAttendancePopup) {
      sendNotification('Você recebeu uma mensagem', {
        body: 'Volte para conferir!',
        icon: '/favicon.svg',
        url: '/app/chat'
      });
    }

    dispatch(setUnreadMessage(message));
  });

  socketInstance.on(ChatWebsocketEvent.ChatbotMessageAck, (data) => {
    dispatch(updateMessageAck(data));
  });

  socketInstance.on(ChatWebsocketEvent.ChatbotMessageReaction, (data) => {
    dispatch(updateMessageReaction(data));
  });

  socketInstance.on(ChatWebsocketEvent.ChatbotMessageRevoke, (data) => {
    dispatch(updateMessageRevoke(data));
  });

  socketInstance.on(ChatWebsocketEvent.ChatBotMessageEdit, (data) => {
    dispatch(updateMessage(data));
  });

  socketInstance.on(ChatWebsocketEvent.Disconnect, () => {
    dispatch(setSocketConnected(false));
  });

  // TRATAMENTO PARA MENSAGENS QUE DERAM ERRO AO SER ENVIADAS
  socketInstance.on(ChatWebsocketEvent.ChatbotSendError, () => {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Erro ao enviar mensagem',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    // dispatch(
    //   setUserChatHistorySuccess({
    //     ...message,
    //     newChatMessage: true,
    //     sendError: true,
    //     createdAt: new Date().toISOString()
    //   })
    // );
  });
};
