import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { resetNotificationSound } from 'store/reducers/soundSlice';

const SoundNotification = () => {
  const dispatch = useDispatch<AppDispatch>();
  const play = useSelector((state: RootState) => state.sound.play);

  useEffect(() => {
    if (play) {
      const audio = new Audio('/sounds/notification.wav');
      audio.play().catch((err) => console.error('Erro ao tocar o áudio:', err));

      audio.onended = () => {
        dispatch(resetNotificationSound());
      };
    }
  }, [play, dispatch]);

  return null;
};

export default SoundNotification;
