import { createSlice } from '@reduxjs/toolkit';

interface SoundState {
  play: boolean;
}

const initialState: SoundState = {
  play: false
};

const soundSlice = createSlice({
  name: 'sound',
  initialState,
  reducers: {
    playNotificationSound(state) {
      state.play = true;
    },
    resetNotificationSound(state) {
      state.play = false;
    }
  }
});

export const { playNotificationSound, resetNotificationSound } = soundSlice.actions;
export default soundSlice.reducer;
