import applications from 'menu-items/applications';
import { useSelector } from 'store';
import { NavItemType } from 'types/menu';

const hasPermission = (userPermissions: string[], requiredPermissions: string[] = []) => {
  if (!requiredPermissions.length) return true;
  const userPermissionsSet = new Set(userPermissions);
  return requiredPermissions.some((permission) => userPermissionsSet.has(permission));
};

const useFilteredApplications = (): NavItemType[] => {
  const { user } = useSelector((state) => state.auth);

  if (!user?.permissions) return [];

  return applications
    .map((group) => {
      const filteredChildren = (group.children ?? [])
        .map((item) => {
          if (!item.children) {
            return hasPermission(user.permissions, item.permissions) ? item : null;
          }

          const filteredSubChildren = item.children.filter((child) =>
            hasPermission(user.permissions, child.permissions)
          );

          return filteredSubChildren.length > 0 ? { ...item, children: filteredSubChildren } : null;
        })
        .filter(Boolean);

      return filteredChildren.length > 0 ? { ...group, children: filteredChildren } : null;
    })
    .filter(Boolean) as NavItemType[];
};

export default useFilteredApplications;
