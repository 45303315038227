import { useEffect } from 'react';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Snackbar from 'components/@extended/Snackbar';
// import Customization from 'components/Customization';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Notistack from 'components/third-party/Notistack';

// auth-provider
import DisconnectedModal from 'components/DisconnectedModal';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import ProfilePage from 'layout/MainLayout/Header/HeaderContent/Profile';
import { initWS } from 'pages/apps/chat/chat-websocket-events';
import SoundNotification from 'utils/SoundNotification';
import NotificationManager from 'components/NotificationManager';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext'q;
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== ////
const App = () => {
  useEffect(() => {
    initWS();
  }, []);

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <Notistack>
                <SoundNotification />
                <Routes />
                <ProfilePage />
                <Snackbar />
                <DisconnectedModal />
                <NotificationManager />
              </Notistack>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
