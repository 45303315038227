import Loader from 'components/Loader';
import Error404 from 'pages/maintenance/error/404';
import { ReactNode } from 'react';
import { useSelector } from 'store';

interface PermissionGuardProps {
  children: ReactNode;
  requiredPermissions: string[];
}

const routePermissions = {
  home: [],
  chat: ['manage_chat'],
  'relatorio-atendimentos': ['report_attendance'],
  contatos: ['manage_contact'],
  grupos: ['manage_contact_group'],
  canais: ['manage_channel'],
  departamento: ['manage_department'],
  campanhas: ['manage_campaign'],
  'dashboard-campanhas': ['dashboard_campaign'],
  usuario: ['manage_user'],
  tags: ['manage_tag']
};

const PermissionGuard = ({ children, requiredPermissions }: PermissionGuardProps) => {
  const { user } = useSelector((state) => state.auth);

  if (requiredPermissions.length === 0) {
    return <>{children}</>;
  }

  if (!user) {
    return <Loader />;
  }

  if (user && !user.permissions?.some((perm) => requiredPermissions.includes(perm))) {
    return <Error404 />;
  }

  return <>{children}</>;
};

export { PermissionGuard, routePermissions };
