// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project-imports
import calendar from './calendar';
import cartReducer from './cart';
import chat from './chat';
import channel from './channel';

import invoice from './invoice';
import kanban from './kanban';
import menu from './menu';
import productReducer from './product';
import snackbar from './snackbar';
import socket from './socket';
import auth from './auth';
import tags from './tags';
import users from './users';
import campaigns from './campaigns';
import departaments from './departaments';
import soundReducer from './soundSlice';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  channel,
  users,
  tags,
  departaments,
  campaigns,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  kanban,
  invoice,
  socket,
  auth,
  sound: soundReducer
});

export default reducers;
