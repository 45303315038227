import { lazy } from 'react';

// project-imports
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { PermissionGuard, routePermissions } from 'contexts/PermissionGuard';

// render - applications
const AppHome = Loadable(lazy(() => import('pages/home')));
const AppChat = Loadable(lazy(() => import('pages/apps/chat/chat')));
const AppServiceReports = Loadable(lazy(() => import('pages/apps/services/reports')));
const AppListContats = Loadable(lazy(() => import('pages/apps/customers/list')));
const AppGroupContacts = Loadable(lazy(() => import('pages/apps/customers/group')));
const AppChannels = Loadable(lazy(() => import('pages/apps/channels/')));
const AppDepartaments = Loadable(lazy(() => import('pages/apps/departaments')));
const AppCampaigns = Loadable(lazy(() => import('pages/apps/campaigns')));
const AppCampaignsDashboard = Loadable(lazy(() => import('pages/apps/campaigns/dashboard')));
const AppUsers = Loadable(lazy(() => import('pages/apps/users')));
const AppTags = Loadable(lazy(() => import('pages/apps/tags')));

// ==============================|| MAIN ROUTES ||============================== //

const AppsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: (
            <PermissionGuard requiredPermissions={routePermissions.home}>
              <AppHome />
            </PermissionGuard>
          )
        },
        {
          path: 'apps',
          children: [
            {
              path: 'chat',
              element: (
                <PermissionGuard requiredPermissions={routePermissions.chat}>
                  <AppChat />
                </PermissionGuard>
              )
            },
            {
              path: 'relatorio-atendimentos',
              element: (
                <PermissionGuard requiredPermissions={routePermissions['relatorio-atendimentos']}>
                  <AppServiceReports />
                </PermissionGuard>
              )
            },
            {
              path: 'contatos',
              children: [
                {
                  path: 'lista',
                  element: (
                    <PermissionGuard requiredPermissions={routePermissions.contatos}>
                      <AppListContats />
                    </PermissionGuard>
                  )
                },
                {
                  path: 'grupos',
                  element: (
                    <PermissionGuard requiredPermissions={routePermissions.grupos}>
                      <AppGroupContacts />
                    </PermissionGuard>
                  )
                }
              ]
            },
            {
              path: 'canais',
              element: (
                <PermissionGuard requiredPermissions={routePermissions.canais}>
                  <AppChannels />
                </PermissionGuard>
              )
            },
            {
              path: 'departamento',
              element: (
                <PermissionGuard requiredPermissions={routePermissions.departamento}>
                  <AppDepartaments />
                </PermissionGuard>
              )
            },
            {
              path: 'campanhas',
              element: (
                <PermissionGuard requiredPermissions={routePermissions.campanhas}>
                  <AppCampaigns />
                </PermissionGuard>
              )
            },
            {
              path: 'dashboard-campanhas',
              element: (
                <PermissionGuard requiredPermissions={routePermissions['dashboard-campanhas']}>
                  <AppCampaignsDashboard />
                </PermissionGuard>
              )
            },
            {
              path: 'usuario',
              element: (
                <PermissionGuard requiredPermissions={routePermissions.usuario}>
                  <AppUsers />
                </PermissionGuard>
              )
            },
            {
              path: 'tags',
              element: (
                <PermissionGuard requiredPermissions={routePermissions.tags}>
                  <AppTags />
                </PermissionGuard>
              )
            }
          ]
        }
      ]
    }
  ]
};

export default AppsRoutes;
