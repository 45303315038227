import { useCallback } from 'react';

export const requestNotificationPermission = async (): Promise<NotificationPermission> => {
  if (!('Notification' in window)) {
    console.error('Este navegador não suporta notificações.');
    return 'denied';
  }

  if (Notification.permission === 'default') {
    const permission = await Notification.requestPermission();
    return permission;
  }

  return Notification.permission;
};

export const sendNotification = (title: string, options?: NotificationOptions & { url?: string }) => {
  if (document.visibilityState === 'hidden' && Notification.permission === 'granted') {
    const notification = new Notification(title, options);
    if (options?.url) {
      notification.onclick = () => {
        const existingWindow = (Array.from(window.open('', '_self')?.opener?.windows || []) as Window[]).find(
          (win: Window) => win.location.href === options.url
        );
        if (existingWindow) {
          existingWindow.focus();
        } else {
          window.open(options.url, '_blank');
        }
      };
    }
  }
};

const useNotifications = () => {
  const requestPermission = useCallback(async () => {
    const permission = await requestNotificationPermission();
    if (permission === 'granted') {
      console.log('Notificações permitidas pelo usuário.');
    } else {
      console.warn('Permissão de notificações negada ou não concedida.');
    }
  }, []);

  return { requestPermission, sendNotification };
};

export default useNotifications;
