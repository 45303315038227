import { useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { ArrowCircleDown, Notification } from 'iconsax-react';
import NotificationSettings from './NotificationSettings';

const SettingTab = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index === selectedIndex ? null : index);
    setShowOptions(index !== selectedIndex ? true : !showOptions);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <List
        component="nav"
        sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}
      >
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={() => handleListItemClick(0)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            m: 0,
            borderBottomRightRadius: selectedIndex === 0 ? 0 : null,
            borderBottomLeftRadius: selectedIndex === 0 ? 0 : null
          }}
        >
          <ListItemIcon>
            <Notification
              variant="Bulk"
              size={18}
            />
          </ListItemIcon>
          <ListItemText primary="Notificações" />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transform: selectedIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease'
            }}
          >
            {<ArrowCircleDown />}
          </Box>
        </ListItemButton>
      </List>

      {selectedIndex === 0 && <NotificationSettings />}
    </Box>
  );
};

export default SettingTab;
